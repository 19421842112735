import React from "react"

import WebsiteLayout from '../components/site/website_layout'
import Educators from '../components/site/educators'

export default () => (
  <WebsiteLayout>
    <Educators />
  </WebsiteLayout>
);
